import React, {useState} from 'react'
import "./contact.scss"
import emailjs from 'emailjs-com';
import validator from 'validator'
import {motion, AnimatePresence} from 'framer-motion'
import FadeInWhenVisible from '../fadeInWhenVisible/FadeInWhenVisible';

const Contact = () => {
    const [name_error, set_name_error] = useState('');
    const [email_error, set_email_error] = useState('');
    const [message_error, set_message_error] = useState('');

    const [name_border] = useState('none');
    const [email_border] = useState('none');
    const [message_border] = useState('none');
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);


    function sendEmail(e) {
        e.preventDefault();
        console.log(e.target[0]);
        let name_bool = false;
        let email_bool = false;
        let message_bool = false;
        console.log(e.target[1]);
        console.log(e.target[1].innerText);
        if (name.trim() === '') {
            set_name_error("A name is required.");
        } else {
            set_name_error('');
            name_bool = true;
        }

        if (email.trim() === '') {
            set_email_error("An email is required.");
        } else if (!validator.isEmail(email)){
            set_email_error("Invalid Email.");
        } else {
            set_email_error('');
            email_bool = true;
        }

        if (message.trim() === '') {
            set_message_error("A message is required.");
            
        } else {
            set_message_error('');
            message_bool = true;
        }

        if (name_bool && email_bool && message_bool) {
            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID)
            .then((result) => {
                console.log(result.text);
                setName('');
                setEmail('');
                setMessage('');
                setSuccess(true);
            }, (error) => {
                console.log(error.text);
            });
        }
      }

      const handleInput = (e) => {
        const input_name = e.target.name;
        const input_value = e.target.value;
        console.log(input_value);

        if (input_name === 'name') {
            setName(input_value);
        } else if (input_name === 'email') {
            setEmail(input_value);
        } else if (input_name === 'message') {
            setMessage(input_value);
        }

      };


    return (
        <div className = "contact" id = "contact">
            <FadeInWhenVisible>
            <AnimatePresence>
            {success ? 
            <motion.h1 key = "h1" initial ={{scale: 1.1}}>
            Your email was successfully sent!
            </motion.h1> 
            
            : 
                
      
                <motion.div className = "container"
                    key = "modal"
                    initial={{ x: -500, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 500, opacity: 0 }}
                    transition = {{delay: 0.5}}
                                >
                <h1>Contact Me</h1>
                <form noValidate className="contact_form" onSubmit={sendEmail}>
                    <input type="hidden" name="contact_number" />
                    <input style = {{border: name_border}} type="text" name="name" placeholder ="Name" value = {name} onChange={(e) => handleInput(e)}/>
                    <div className = "error">{name_error}</div>
                    <input style ={{border: email_border}} type="email" name="email" value = {email} onChange={(e) => handleInput(e)} placeholder ="Email" />
                    <div className = "error">{email_error}</div>
                    <textarea name="message" value = {message} onChange={(e) => handleInput(e)} placeholder ="Message..."/>
                    <div className = "error">{message_error}</div>
                    <motion.input 
                    whileHover={{
                        scale: 1.07,
                        transition: { duration: 1 },
                        
                    }}
                    whileTap={{ scale: 0.9 }}
                    style = {{message_border}} className = "submit_button" type="submit" value="Send" />
                </form>
                
                </motion.div>
            
            }
            </AnimatePresence>
            </FadeInWhenVisible>
        </div>
    )
}

export default Contact
