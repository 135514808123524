export const projects = [
    {
        title: 'Sneaker Ecommerce ',
        description: "Customers can log in through Google and Facebook OAuth and add/remove sneakers to cart to checkout. Sneakers are checked out using Stripe API and orders are logged in the account tab.",
        image: '/portfolio/assets/project/sneaker-ecommerce.png',
        created: 'ReactJS, Redux, ExpressJS, NodeJS, and MongoDB',
        source: 'https://github.com/joeyhwang/sneaker-ecommerce-web',
        link: 'https://warm-plains-75848.herokuapp.com/',
        video: ''
    },
    {
        title: 'Frontend Mentor',
        description: "Created landing pages and components from designs. Each challenge template consists of images and a style guide, which lays out the mobile/desktop sizes and font colors/size.",
        image: "/portfolio/assets/project/frontend-mentor.png",
        created: "HTML, CSS, Vanilla JavaScript, TypeScript, ReactJS",
        source: "https://github.com/joeyhwang/Frontend-Mentor",
        link: 'https://jh-frontend-mentor-solutions.netlify.app/',
        video: '',
    },
    {
        title: 'AnimeSite',
        description:"Integrated MyAnimeList API to allow users to query, filter, and browse through hundreds of anime.",
        image: '/portfolio/assets/project/anime.png',
        created: 'ReactJS, NextJS',
        source: 'https://github.com/joeyhwang/Anime-Site',
        link: 'https://anime-site-gamma.vercel.app/',
        video: ''
    },
    {
        title: 'TFT Stats',
        description: "Teamfight Tactics players can search for their summoner name and TFT Stats will display a page that shows their profile stats and match history details.",
        image: '/portfolio/assets/project/tftstats.png',
        created: 'ReactJS, Redux, ExpressJS, NodeJS, and Material UI',
        source: 'https://github.com/joeyhwang/TFTStats-web',
        link: '',
        video: '',
    },



    // {
    //     title: 'Restie',
    //     description: "An app created to solve food indecision. Users can press a button to find a random restaurant within a 25 mile radius based on their preferred settings (ratings, price, category) utilizing Yelp API.",
    //     image: '/portfolio/assets/project/restie.png',
    //     created: 'Swift and Xcode',
    //     source: 'https://github.com/joeyhwang/Restie-iOS',
    //     link: '',
    //     video: '',
    // }
  ];