import './portfolio.scss'
import {projects} from './projectlist.jsx';
// import { motion } from "framer-motion";
import { AiFillGithub } from 'react-icons/ai';
import FadeInWhenVisible from '../fadeInWhenVisible/FadeInWhenVisible';
const Portfolio = () => {

    const displayYoutubeVideo = (i) => {
        switch (i) {
            case 0:
                return ( 
                <iframe className = "youtubeVideo" src="https://www.youtube.com/embed/qgrryEHVzv4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                )
            default:
                return <></>
        }
    }

    return (
        <div className = "portfolio" id = "portfolio">   
                <FadeInWhenVisible>
                <h1>Portfolio</h1>
                </FadeInWhenVisible>
                {projects.map((p, i)=> {
                    return (
                        <FadeInWhenVisible key = {i} >
                        <div 
                         className = {"container " + (i % 2 === 1 ? 'reverse' : "")} >
                            <div className = {"imageContainer " + (i % 2 === 1 ? 'reverse': "")}>
                            
                            {p.video === '' ? 
                                <img className = "image" src = {p.image} alt = {`${p.title}`}></img>
                            :
                                displayYoutubeVideo(i)
                            }
                            
                            </div>

                            <div className = "textContainer" >
                                <h2>{p.title}</h2>
                                <p>{p.description}</p>
                                <strong>{p.created}</strong>
                                <div className = "linksContainer">
                                    <a className = "icon" href = {p.source} rel = "noreferrer" target="_blank">
                                        <AiFillGithub size = "2rem"/>
                                    </a>
                                    {p.link !== "" && 
                                    <a href = {p.link} rel = "noreferrer" target="_blank">
                                     <button>Live Website</button>

                                    </a>
                                    
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        </FadeInWhenVisible>
                    
                    )
                })}
            
            
        </div>
    )
}

export default Portfolio
