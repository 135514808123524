import React from 'react'
import './topbar.scss'
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';

const Topbar = ({menuOpen, setMenuOpen} ) => {



    return (
        <div className = {"topbar " + (menuOpen ? 'active ' : "")  }>
            <div className = "wrapper">
                <div className = "left">
                    <a href = "#intro" className = "logo">JH</a>
                    <div className = "itemContainer">
                        <a className = {"icon " + (menuOpen && 'active')} href = "https://github.com/joeyhwang" rel = "noreferrer" target="_blank">
                            <AiFillGithub size = "2rem" />
                        </a>
                        
                    </div>
                    <div className = "itemContainer">
                        <a className = {"icon " + (menuOpen && 'active')} href = "https://www.linkedin.com/in/joeyhwang01/" rel = "noreferrer" target="_blank">
                            <AiFillLinkedin size = "2rem"/>
                        </a>
                    </div>

                    {/* <div  className = "itemContainer">
                    <a className = {"icon " + (menuOpen && 'active')} href = "/portfolio/assets/Joey_Hwang_Resume.pdf"  rel = "noreferrer" target="_blank">
                        <img src = "/portfolio/assets/resume_icon.png" className = "resumeImage" alt = "resume"  ></img>
                    </a>
                </div> */}

            </div>
          
                <div className = "right">
                    <div className = "hamburger" onClick = {()=>setMenuOpen(!menuOpen)}>
                        <span className = "line1"></span>
                        <span className = "line2"></span>
                        <span className = "line3"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Topbar
