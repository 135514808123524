import React from 'react'
import "./intro.scss"
import { motion } from "framer-motion"
import Typewriter from 'typewriter-effect'

const Intro = () => {
    return (
        <div className = "intro" id = "intro">
            <div className = "left">
                <motion.div className = "imgContainer"
                    initial = {{opacity: 0}}
                    animate = {{opacity: 1}}
                    transition = {{delay: 1.5, duration: 1}}
                >
                    <img src = "/portfolio/assets/profile2.jpg" alt = ""/>
                </motion.div>
            </div>
            <div className = "right">
                <motion.div className = "wrapper"
                    initial = {{x: '100vw'}}
                    animate = {{x : 0}}
                    transition = {{type: 'spring', delay: 0.5}}
                    
                >
                <Typewriter
                options = {{loop: true }}
     
                onInit={(typewriter) => {
                    typewriter.typeString(`<h1>Hi I'm Joey</h1>`)
                    .pauseFor(1000)
                    .deleteAll() 
                    .typeString(`<h1>I love learning</h1>`)
                    .pauseFor(1000)
                    .deleteChars(8) 
                    .typeString(`<h1>coding</h1>`)
                    .pauseFor(1000)
                    .deleteChars(6)
                    .typeString(`<h1>strategy games</h1>`)
                    .pauseFor(1000)
                    .start();
                    
                }}
            />
            <p style = {{fontSize: '1rem'}}>Recent UCI graduate and Junior Front-End Developer eager to improve my skills and grow to become a better person and developer.</p>
  
            </motion.div>

            
            </div>
            <img className = "blob" src = "/portfolio/assets/blob.svg" alt = ""></img>
            <img className = "wave" src = "/portfolio/assets/wave1.svg" alt = ""></img>

        </div>
    )
}

export default Intro
