import React from 'react'
import "./about.scss"
import { aboutSkills } from './aboutlist'
import FadeInWhenVisible from '../fadeInWhenVisible/FadeInWhenVisible';

const About = () => {
    return (
        <div className = "about" id = "about">
            <div className = "container">
                <div className = "textContainer"
                >
                    <h2 className = "aboutMe">About me</h2>
                    <FadeInWhenVisible>
                        <div className = "paragraphContainer">
                            <p className ="aboutParagraph">Hi I'm Joey and I'm a junior front-end developer who loves creating beautiful websites. I'm always pushing myself to learn
                            new technologies and to constantly improve myself to become a better person and developer. <strong> Success consists of going from failure to failure without loss of enthusiasm.</strong>
                            </p>
                        </div>
                    </FadeInWhenVisible>
                </div>

                    
                <div className = "textContainer">
                    <h2 className = "hobbies">Hobbies</h2>
                    <FadeInWhenVisible>
                        <div className = "paragraphContainer">
                            <p> I've played many video games in the past such as Starcraft 2, Hearthstone, League of Legends, and Teamfight Tactics, and I've reached the highest rank tier in each of them except for Starcraft. 
                            My most recent game was Teamfight Tactics(LoL Auto Chess) competitively and consistently reached top 20 Challenger in North America in multiple sets. I competed in many tournaments and won the 11.4 Challenger Series Week 8. 
                            </p>
                        </div>
                    </FadeInWhenVisible>
    
                </div>
                <div className = "textContainer">
                    <h2 className = "skills">Skills</h2>
                    <FadeInWhenVisible>
                        <div className = "skillsContainer">
                        {aboutSkills.map((a , i)=> {
                            return(
                            <div key = {i} className = "skillContainer">
                                <img src = {a.image} alt = "" />
                                <h5>{a.title} </h5>
                            </div>
                            )
                        })}
                        </div>
                        
                    </FadeInWhenVisible>
                        
                </div>
                

            
            </div>
            <img className = "wave" src = "/portfolio/assets/wave2.svg" alt = ""></img>
            <img className = "wave2" src = "/portfolio/assets/wave3.svg" alt = ""></img>
        </div>
    )
}

export default About
