

export const aboutSkills = [
    {
        title: "JavaScript",
        image: "/portfolio/assets/about/javascript.png"
    },

    {
        title: "TypeScript",
        image: "/portfolio/assets/about/typescript.png"
    },

    {
        title: "Python",
        image: "/portfolio/assets/about/python.png"
    },

    {
        title: "CSS3",
        image: "/portfolio/assets/about/css.png"
    },
    {
        title: "SASS",
        image: "/portfolio/assets/about/sass.png"
    },
    {
        title: "ReactJS",
        image: "/portfolio/assets/about/react.png"
    },
    {
        title: "NodeJS",
        image: "/portfolio/assets/about/nodejs.png"
    },
    {
        title: "Git",
        image: "/portfolio/assets/about/git.png"
    },
    {
        title: "MongoDB",
        image: "/portfolio/assets/about/mongodb.png"
    },
]