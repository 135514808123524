import React, {useState} from 'react';
import "./app.scss";
import Topbar from './components/topbar/Topbar';
import Portfolio from './components/portfolio/Portfolio';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Intro from './components/intro/Intro';
import Menu from './components/menu/Menu';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    
    <div className = "app">
    <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}></Topbar>
    <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <div className = "sections">
        <Intro/>
        <Portfolio/>
        <About/>
        <Contact/>
      </div>
    </div>
    
  );
}

export default App;
